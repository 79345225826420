import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { WarningOutlined, Check, Info } from '@material-ui/icons';

const StyledAlert = withStyles(() => ({
  root: {
    fontSize: 13,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'fit-content',

  },
  filledError: {
    backgroundColor: '#CE3100',
    height: 36,
  },
  filledSuccess: {
    backgroundColor: '#B2EDD6',
    color: '#000000',
  },
  filledWarning: {
    backgroundColor: '#F5ECB2',
    color: '#000000',
  },
}))(Alert);

export default function CustomAlert({ children, severity }) {
  switch (severity) {
    case 'error':
      return (
        <StyledAlert variant="filled" severity={severity} icon={<WarningOutlined />}>
          {children}
        </StyledAlert>
      );
    case 'warning':
      return (
        <StyledAlert variant="filled" severity={severity} icon={<Info />}>
          {children}
        </StyledAlert>
      );
    default:
      return (
        <StyledAlert variant="filled" severity={severity} icon={<Check />}>
          {children}
        </StyledAlert>
      );
  }
}

CustomAlert.propTypes = {
  children: PropTypes.node.isRequired,
  severity: PropTypes.string.isRequired,
};
