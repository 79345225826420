import React from 'react';

import SEO from 'src/components/seo';
import ResetPassword from 'src/features/reset-password';
import queryString from 'query-string';

export default function Onboarding(props) {
  const pageProps = props;
  // const token = pageProps?.params && pageProps?.params.token ? pageProps.params.token : false;
  const token = queryString.parse(location.search) && queryString.parse(location.search).token ? queryString.parse(location.search).token : false;

  return (
    <>
      <SEO title="Login" />

      <ResetPassword token={token} />
    </>
  );
}
