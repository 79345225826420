import React from 'react';

import { Hidden } from '@material-ui/core';

import DesktopResetPassord from './desktopResetPassword';
import MobileResetPassord from './mobileResetPassword';

export default function ({ token }) {
  return (
    <>
      <Hidden only="xs">
        <DesktopResetPassord token={token} />
      </Hidden>

      <Hidden smUp>
        <MobileResetPassord token={token} />
      </Hidden>
    </>
  );
}
