/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  makeStyles,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Button,
  Hidden,
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Alert from 'src/components/alert';

import { resetPassword } from 'src/api/login';

const useStyles = makeStyles(theme => ({
  buttonWrapper1: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(5),
  },
  buttonWrapper2: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    '& > *': {
      height: 53,
      [theme.breakpoints.down('sm')]: {
        width: 250,
      },
    },
  },
  icon: {
    margin: theme.spacing(0, 1),
  },
}));

const formSchema = yup.object().shape({
  token: yup
    .string()
    .required('Url is required'),
  password: yup
    .string()
    .min(8, 'Password must be contain at least 8 characters')
    .matches(/[0-9]/, 'Password requires at least one digit')
    .matches(/[a-z]/, 'Password requires at least one lowercase letter')
    .matches(/[A-Z]/, 'Password requires at least one uppercase letter')
    .matches(/[^\w]/, 'Password requires at least one special symbol')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password and confirm password should match')
    .required('Confirm password is required'),
});

export default function ResetPasswordForm({ token }) {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(false);

  const formik = useFormik({
    initialValues: {
      token: token || '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: formSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      const { status, message } = await resetPassword(values);

      if (status === 'success') {
        navigate('/login/', { replace: true });
      } else {
        setError(message);
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <Typography variant="h1" align="center">
        Reset password
      </Typography>

      <form className={classes.form}>
        <input
          id="token"
          name="token"
          type="hidden"
          value={formik.values.token}
        />

        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel htmlFor="password">Password</InputLabel>

          <OutlinedInput
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="password"
            label="Password*"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={formik.values.password}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {
                  showPassword
                    ? (
                      <VisibilityOff color="primary" className={classes.icon} />
                    )
                    : (
                      <Visibility color="primary" className={classes.icon} />
                    )
                }
              </IconButton>
            }
          />

          <FormHelperText component="div">
            {
              formik.errors.password && formik.touched.password
                ? (
                  <Alert variant="filled" severity="error">
                    {formik.errors.password}
                  </Alert>
                )
                : null
            }
          </FormHelperText>
        </FormControl>

        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel htmlFor="confirmPassword">Confirm password</InputLabel>

          <OutlinedInput
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="confirmPassword"
            label="Confirm password*"
            name="confirmPassword"
            type={showConfirmPassword ? 'text' : 'password'}
            value={formik.values.confirmPassword}
            endAdornment={
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={handleClickShowConfirmPassword}
                edge="end"
              >
                {
                  showConfirmPassword
                    ? (
                      <VisibilityOff color="primary" className={classes.icon} />
                    )
                    : (
                      <Visibility color="primary" className={classes.icon} />
                    )
                }
              </IconButton>
            }
          />

          <FormHelperText component="div">
            {
              formik.errors.confirmPassword && formik.touched.confirmPassword
                ? (
                  <Alert variant="filled" severity="error">
                    {formik.errors.confirmPassword}
                  </Alert>
                )
                : null
            }
          </FormHelperText>
        </FormControl>

        <FormHelperText component="div">
          {error && <Alert severity="error">{error}</Alert>}
        </FormHelperText>

        <div className={classes.buttonWrapper2}>
          <Button
            color="secondary"
            variant="contained"
            disableElevation
            disabled={formik.isSubmitting}
            onClick={formik.submitForm}
            endIcon={
              <Hidden smDown>
                <ArrowForwardIcon className={classes.icon} />
              </Hidden>
            }
          >
            Reset password
          </Button>
        </div>
      </form>
    </>
  );
}
