import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles, Dialog, IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import StepsImage from 'src/components/stepsImage';

// import LoginForm from './loginForm';
import ResetPasswordForm from './resetPasswordForm';

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    '& .MuiDialog-paper': {
      width: 750,
    },
  },
  content: {
    maxWidth: 475,
    margin: 'auto',
  },
  imageWrapper: {
    width: '50%',
    marginTop: -70,
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(2),
  },
}));

export default function DesktopLogin({ token }) {
  const classes = useStyles();

  return (
    <Dialog maxWidth="lg" open className={classes.dialog}>
      <div className={classes.header}>
        <IconButton
          onClick={() => {
            navigate('/');
          }}
        >
          <CloseIcon color="secondary" />
        </IconButton>
      </div>
      <div className={classes.content}>
        <ResetPasswordForm token={ token }/>
      </div>
      <div className={classes.imageWrapper}>
        <StepsImage step="step1" />
      </div>
    </Dialog>
  );
}
